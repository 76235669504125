@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./slick.css";

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

@keyframes blinkMove {
  0%,
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }

  50% {
    opacity: 0;
    transform: translate(10px, 10px);
    /* Adjust the values for slight movement */
  }
}

.night {
  position: absolute;
  transform: rotateZ(40deg);
}

.star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 1px;
  background: linear-gradient(-45deg, #fff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #fff);
  animation:
    tail 3s ease-in-out infinite,
    falling 3s ease-in-out infinite;
}

.shadow-class {
  text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
}

.campaign-text-shadow {
  text-shadow: 0px 10px 18px rgba(0, 0, 0, 0.22);
}

.credit-banner-header-text {
  font-weight: bold;
  background: -webkit-linear-gradient(top, #8f57f8, #6772e1);
  background-clip: text;
  -webkit-text-stroke: 4px #7547ff;
  color: white;
}

.campaign-header-text {
  font-weight: bold;
  background: -webkit-linear-gradient(top, #d7c4f8, #ffffff);
  background-clip: text;
  -webkit-text-stroke: 4px white;
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes falling {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

.star::before,
.star::after {
  content: "";
  position: absolute;
  top: calc(50%-1px);
  right: 0;
  height: 1px;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 255, 0),
    #fff,
    rgba(0, 0, 255, 0)
  );
  border-radius: 100%;
  transform: translateX(50%) rotateZ(45deg);
  animation: shining 3s ease-in-out infinite;
}

@keyframes shining {
  0% {
    width: 0;
  }

  50% {
    width: 15px;
  }

  100% {
    width: 0;
  }
}

.star::after {
  transform: translateX(50%) rotateZ(-45deg);
}

.star:nth-child(1) {
  top: calc(50% - 100px);
  left: calc(50% - 250px);
  animation-delay: 1s;
}

.star:nth-child(1)::before,
.star:nth-child(1)::after {
  animation-delay: 1s;
}

.star:nth-child(2) {
  top: calc(50% - 50px);
  left: calc(50% - 200px);
  animation-delay: 1.2s;
}

.star:nth-child(2)::before,
.star:nth-child(2)::after {
  animation-delay: 1.2s;
}

.star:nth-child(3) {
  top: calc(50% - 0px);
  left: calc(50% - 150px);
  animation-delay: 1.4s;
}

.star:nth-child(3)::before,
.star:nth-child(3)::after {
  animation-delay: 1.4s;
}

.star:nth-child(4) {
  top: calc(50% - -50px);
  left: calc(50% - 200px);
  animation-delay: 1.6s;
}

.star:nth-child(4)::before,
.star:nth-child(4)::after {
  animation-delay: 1.6s;
}

.star:nth-child(5) {
  top: calc(50% - -100px);
  left: calc(50% - 250px);
  animation-delay: 1.8s;
}

.star:nth-child(5)::before,
.star:nth-child(5)::after {
  animation-delay: 1.8s;
}
