#use-cases .slick-slide {
  opacity: 0.1;
}
#use-cases .slick-slide.slick-active {
  opacity: 1;
}
#use-cases .slick-dots {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  bottom: -50px;
}
#use-cases .slick-dots li {
  margin: 0 0.2px;
  display: flex;
  width: 38px;
}
#use-cases .slick-dots li button {
  width: 100%;
}
#use-cases .slick-dots li button:before {
  content: "";
  height: 2px;
  width: 38px;
  border-radius: 3px;
  background-color: #d8d8d8;
}
#use-cases .slick-dots li.slick-active button:before {
  background-color: #6e6e6e;
}
.slick-slide > div,
.slick-slide > div > div {
  height: 100%;
}
.slick-track {
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  align-items: stretch !important;
}
#partners .slick-slider,
#partners .slick-list,
#partners .slick-track {
  height: 100%;
}
#partners .slick-slide {
  margin: 0 25px;
}
#partners .slick-track {
  gap: 8px;
}
#use-cases .slick-slide {
  height: inherit !important;
}
@media screen and (min-width: 1024px) {
  #use-cases .slick-dots {
    gap: 7px;
  }
  #use-cases .slick-slide {
    opacity: 1;
  }
  #use-cases .slick-slide:not(.slick-active) h2,
  #use-cases .slick-slide:not(.slick-active) p,
  #use-cases .slick-slide:not(.slick-active) a,
  #use-cases .slick-slide:not(.slick-active) img {
    opacity: 0.3;
  }
  #use-cases .slick-slide:not(.slick-active) h2,
  #use-cases .slick-slide:not(.slick-active) p,
  #use-cases .slick-slide:not(.slick-active) a,
  #use-cases .slick-slide:not(.slick-active) img {
    opacity: 0.3;
  }
  #partners .slick-slide {
    margin: 0;
  }
  #partners .slick-track {
    gap: 20px;
  }
}
